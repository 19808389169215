<form [formGroup]="contactUsForm" novalidate (ngSubmit)="onSubmit()" class="flex vertical layout">
  <mat-card class="contact-us-card" class="flex vertical layout">
    <mat-card-content class="flex vertical layout">
      <h3>Contact Us</h3>
      <div class="flex vertical layout" *ngIf="!submited">
        <div class="row">
          <mat-form-field class="full-width">
            <input matInput placeholder="First name" formControlName="firstName">
            <mat-error *ngIf="contactUsForm.controls['firstName'].hasError('required')">
              First name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="full-width">
            <input matInput placeholder="Last name" formControlName="lastName">
            <mat-error *ngIf="contactUsForm.controls['lastName'].hasError('required')">
              Last name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="full-width">
            <input matInput placeholder="Email" formControlName="email" type="email">
            <mat-error *ngIf="contactUsForm.controls['email'].hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="contactUsForm.controls['email'].hasError('pattern')">
              Email is <strong>invalid</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="full-width">
            <input matInput placeholder="Company" formControlName="company">
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="full-width">
            <textarea matInput #message minlength="3" maxlength="800" placeholder="Your message"
              formControlName="message" [rows]="6"></textarea>
            <mat-hint align="end">{{message.value.length}} / 800</mat-hint>
            <mat-error *ngIf="contactUsForm.controls['message'].hasError('required')">
              Message is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="contactUsForm.controls['message'].hasError('minlength')">
              Message is <strong>too short</strong>
            </mat-error>
            <mat-error *ngIf="contactUsForm.controls['message'].hasError('maxlength')">
              Message is <strong>too long</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="flex vertical layout center around-justified" *ngIf="submited">
        <img src="../../assets/images/thank-you.png" alt="Thank you!" height="256" width="256">
      </div>
    </mat-card-content>
    <mat-card-actions class="actions">
      <button mat-raised-button color="primary" type="submit" *ngIf="!submited" [disabled]="loading">Submit</button>
      <button mat-raised-button color="secondary" mat-dialog-close>Close</button>
    </mat-card-actions>
  </mat-card>
</form>