// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseFunctionsBaseUrl: 'https://localhost:6001/kotiz-one/us-central1',
  firebase: {
    apiKey: "AIzaSyBDTOXkvHg3qdHcdbbRTM2wpMEeXfOJQmI",
    authDomain: "kotiz-one.firebaseapp.com",
    databaseURL: "https://kotiz-one.firebaseio.com",
    projectId: "kotiz-one",
    storageBucket: "kotiz-one.appspot.com",
    messagingSenderId: "1034215994743",
    appId: "1:1034215994743:web:91c5eaf34f301553dc1d72"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
