import { ContactUsComponent } from './../contact-us/contact-us.component';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserProfileComponent } from '../profile/user-profile/user-profile.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  isHandset = false;

  @ViewChild('content')
  contentEl: ElementRef;

  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Card 2', cols: 1, rows: 1 },
          { title: 'Card 3', cols: 1, rows: 1 },
          { title: 'Card 4', cols: 1, rows: 1 }
        ];
      }

      return [
        { title: 'Card 1', cols: 2, rows: 1 },
        { title: 'Card 2', cols: 1, rows: 1 },
        { title: 'Card 3', cols: 1, rows: 2 },
        { title: 'Card 4', cols: 1, rows: 1 }
      ];
    })
  );

  constructor(private breakpointObserver: BreakpointObserver, public dialog: MatDialog) {

  }

  openContactUs() {
    const dialogRef = this.dialog.open(ContactUsComponent);
  }

  openUserProfile() {
    const dialogConfig = new MatDialogConfig();
    const contentNativeElement = this.contentEl.nativeElement;
    const contentRect = contentNativeElement.getBoundingClientRect();
    dialogConfig.autoFocus = true;

    if (this.isHandset) {
      dialogConfig.maxWidth = '100vw';
      dialogConfig.width = contentNativeElement.offsetWidth + 'px';
      dialogConfig.height = (contentNativeElement.offsetHeight - 56) + 'px';
      dialogConfig.position = {
        top: (contentRect.top + 56) + 'px',
        left: contentRect.left + 'px',
        bottom: contentRect.bottom + 'px',
        right: contentRect.right + 'px'
      };
    } else {
      dialogConfig.width = '500px';
      dialogConfig.height = '520px';
    }

    const dialogRef = this.dialog.open(UserProfileComponent, dialogConfig);
  }
}
