import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Auth, signOut, signInWithCustomToken } from '@angular/fire/auth';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const detectEthereumProvider = require('@metamask/detect-provider');

interface ChallengeResponse {
  challenge: string;
}

interface VerifyResponse {
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private auth: Auth) { }

  public logout() {
    return this.auth.signOut();
  }

  public login(email: string, address: string) {
    let ethereum: any;
    const url = environment.firebaseFunctionsBaseUrl;
    const httpOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      withCredentials: true
    };

    return from(detectEthereumProvider()).pipe(
      switchMap(async (provider) => {
        if (!provider) {
          throw new Error('Please install MetaMask and create an Ethereum account');
        }
        ethereum = provider;
        return await ethereum.request({ method: 'eth_requestAccounts' });
      }),

      switchMap(() =>
        this.http.post<ChallengeResponse>(
          url + '/getChallenge',
          {
            email: email,
            address: address
          },
          httpOptions
        )
      ),

      switchMap(
        async (response) => {
          const challenge = response.challenge;
          await ethereum.request({
            method: 'personal_sign',
            params: [
              response.challenge,
              ethereum.selectedAddress,
            ],
          })
        }
      ),

      switchMap((signature) =>
        this.http.post<VerifyResponse>(
          url + '/login',
          {
            email: email,
            address: ethereum.selectedAddress,
            signature: signature
          },
          httpOptions
        )
      ),

      switchMap(
       async (response) =>
         await signInWithCustomToken(this.auth, response.token)
      )
    );
  }
}
