<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="isHandset ? 'dialog' : 'navigation'"
    [mode]="isHandset ? 'over' : 'side'" [opened]="isHandset === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list (click)="toggleMenu()">
      <a mat-list-item routerLink="/">Home</a>
      <a mat-list-item routerLink="" (click)="goToBookmark('#fofi')">Kotiz Finance</a>
      <a mat-list-item routerLink="" (click)="goToBookmark('#assistant')">Kotiz Connect</a>
      <a mat-list-item routerLink="" (click)="goToBookmark('#do-it-all')">Kotiz Financial Assistant</a>
      <a mat-list-item routerLink="" (click)="openContactUs()">Contact Us</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content #content>
    <mat-toolbar color="primary">
      <div class="flex horizontal layout justified">
        <img src="../assets/images/kotiz_simple_logo.png" alt="Kotiz Logo" height="42" width="42">
        <img src="../assets/images/kotiz_name.png" alt="Kotiz name" height="25">
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleMenu()" *ngIf="isHandset">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>