import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  welcomeStep = 'welcomeStep';
  warningSrep = 'warningSrep';
  loginInfoStep = 'loginInfoStep';
  emailConfirmationStep = 'emailConfirmationStep';
  userInfoStep = 'userInfoStep';
  signatureStep = 'signatureStep';
  identityStep = 'identityStep';
  identificationStep = 'identificationStep';
  saleStep = 'saleStep';
  confirmationStep = 'confirmationStep';

  saleWorkflow = [
    this.welcomeStep,
    this.warningSrep,
    this.loginInfoStep,
    this.userInfoStep,
    this.signatureStep,
    this.identityStep,
    this.identificationStep,
    this.saleStep,
    this.confirmationStep
  ];

  expressWorkflow = [
    this.loginInfoStep,
    this.userInfoStep,
    this.saleStep,
    this.confirmationStep
  ];

  email = '';
  userEthAddress = '';
  firstName = '';
  lastName = '';
  country = '';
  city = '';
  address = '';
  challenge = '';
  contract = '';
  signedContract = '';
  ethQuantity = 0;
  tokenQuantity = 0;
  tokenEthAddress = '';
  currentStep = '';
  currentWorkflow = [];
  isAuthenticated = false;
  waitingForLogin = false;
  uuid = '';
  logginError = '';

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient, private auth: Auth, private authService: AuthService) { }

  loginInfoStepForm = this.fb.group({
    email: [null, Validators.compose([Validators.required,
    Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
    userEthAddress: [null, Validators.compose([
      Validators.required, Validators.minLength(42), Validators.maxLength(42)])
    ]
  });

  userInfoStepForm = this.fb.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    country: [null, Validators.required],
    city: [null, Validators.required],
    addresse: [null, Validators.compose([
      Validators.required, Validators.minLength(3), Validators.maxLength(800)])
    ]
  });

  saleStepForm = this.fb.group({
    ethQuantity: [null, Validators.required, Validators.min(0.1)],
    tokenQuantity: [null]
  });

  confirmationStepForm = this.fb.group({
  });

  ngOnInit(): void {
    this.clear();
    this.currentStep = this.loginInfoStep;
    this.currentWorkflow = this.expressWorkflow;
    this.auth.onAuthStateChanged((user) => {
      if (user && user.uid) {
        this.isAuthenticated = true;
        if (this.currentStep == this.loginInfoStep && this.waitingForLogin) {
          this.next();
        }
      } else {
        this.isAuthenticated = false;
      }
    });
  }

  next() {
    if (this.currentStep == this.loginInfoStep) {
      this.loginInfoStepProcess();
    } else if (this.currentStep == this.userInfoStep) {
      this.currentStep = this.getNextStep();
    } else if (this.currentStep == this.saleStep) {
      this.currentStep = this.getNextStep();
    }
  }

  back() {
    this.currentStep = this.getPreviousStep();
  }

  getNextStep() {
    let index = this.currentWorkflow.indexOf(this.currentStep);
    return this.currentWorkflow[index + 1];
  }

  getPreviousStep() {
    let index = this.currentWorkflow.indexOf(this.currentStep);
    return this.currentWorkflow[index - 1];
  }

  get isFirstStep() {
    return this.currentWorkflow.indexOf(this.currentStep) == 0;
  }

  get isLastStep() {
    return this.currentWorkflow.indexOf(this.currentStep) == this.currentWorkflow.length - 1;
  }

  isCurrentStep(step: String) {
    return this.currentStep == step;
  }

  clear() {
    this.isAuthenticated = false;
    this.waitingForLogin = false;
    this.logginError = '';
    this.loginInfoStepForm.reset;
    this.userInfoStepForm.reset;
    this.saleStepForm.reset;
    this.confirmationStepForm.reset;
  }

  loginInfoStepProcess() {
    if (!this.isAuthenticated) {
      this.waitingForLogin = true;

      if (!this.loginInfoStepForm.valid) {
        return;
      }

      this.logginError = '';
      this.waitingForLogin = true;
      this.authService.login(this.email, this.userEthAddress)
        .pipe().subscribe({
          error: error => {
            console.error('Email and eth address don\'t match!', error);
            this.waitingForLogin = false;
          }
        });
    } else {
      this.currentStep = this.getNextStep();
    }
  }
}
