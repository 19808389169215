<div class="flex vertical layout grid-container" #content>
  <mat-card id="fofi" class="dashboard-card">
    <mat-card-header>
      <img mat-card-avatar src="../../assets/images/ai.png" class="avatar-image" height="42" width="42">
      <mat-card-title>Kotiz.Finance</mat-card-title>
      <mat-card-subtitle>Decentralized financial advisory for everyone!</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="../../assets/images/kotiz_finance_ws.png" alt="ws">
    <mat-card-content>
      Kotiz.Finance is the utility token used on the Kotiz marketplace to pay for services and promote financial inclusion using the power of Decentralized Finance (DeFi) and other financial solutions on the blockchain. We want to be the Oracle of decentralized financial solutions. We integrate Blockchain with FinTech to solve personal finance challenges, low-to-mid-income savings, and wealth building. We sponsor decentralized open-source finance projects and academic capstone and research programs in decentralized finance.
      <br/>
      Kotiz.Finance reinvents decentralized finance to promote financial inclusion and improve people’s financial lives. Financial advisors can generate leads by rewarding users for their financial performances. Users can use Kotiz.Finance to pay financial advisors. On the other hand, Kotiz Marketplace compensates users for releasing unidentifiable personal data like spending while allowing them complete control over their data. Our reverse marketing enables financial advisors to define targeted profiles, and users can automatically detect new products and services by scanning their financial profiles.
    </mat-card-content>
    <mat-card-actions>
      <div class="flex horizontal layout justified">
        <button mat-raised-button color="primary" (click)="openUserProfile()">Token Sale</button>
        <button type="button" aria-label="Toggle sidenav" mat-icon-button onclick="window.open('https://t.me/kotizfinance', '_blank')">
          <mat-icon class="social-media-icon" svgIcon="telegram"></mat-icon>
        </button>
        <button mat-raised-button color="primary" onclick="window.open('assets/files/kotiz_whitepaper_v4-2.pdf', '_blank')">Whitepaper</button>
      </div>
    </mat-card-actions>
  </mat-card>

  <mat-card id="assistant" class="dashboard-card">
    <mat-card-header>
      <img mat-card-avatar src="../../assets/images/ai.png" class="avatar-image" height="42" width="42">
      <mat-card-title>Kotiz Connect</mat-card-title>
      <mat-card-subtitle>Connect with advisors to make better financial decisions!</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="../../assets/images/kotiz_couple.jpg" alt="Couple">
    <mat-card-content>
      Kotiz is a marketplace for connecting users with financial advisors via a conventional AI chatbot to help low-to-mid-income families make better financial decisions, save money and invest responsibly.
      <br/>
      Low-to-mid-income families are underserved regarding financ ial advisory and management solutions, even if they need them the most. On the other hand, financial advisors struggle with acquiring new customers.
      <br/>
      Financial inclusion is essential to a sustainable economy. Financial services can quickly scale if we provide financial advisory to everyone. Kotiz creates a new generation of micro investors by helping low-to-mid income families make the right financial decision, save money and invest responsibly.
      <br/>
      Our mission is to promote financial inclusion by providing everyone access to financial advisory and management. We are building a high-volume Conversational AI Chatbot with the ability to automatically switch conversations and connect users with a human advisor after an initial evaluation of their financial needs by a robot advisor. Other professionals like doctors and insurance agents can also use our solution to connect with their customers.
      <br/>
      Financial advisors are eager to combat COVID-19 economic crisis but they cannot do it without you! You are
      wealthier than you think! If you aren’t, start taking control of your financial life today!
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" onclick="onTidioChatApiReady()">Get started today!</button>
    </mat-card-actions>
  </mat-card>

  <div class="vertical-spacer-20 flex horizontal layout"></div>

  <mat-card id="do-it-all" class="dashboard-card">
    <mat-card-header>
      <img mat-card-avatar src="../../assets/images/ai.png" class="avatar-image" height="42" width="42">
      <mat-card-title>Kotiz Financial Assistant</mat-card-title>
      <mat-card-subtitle>Take control of your financial life!</mat-card-subtitle>
    </mat-card-header>
    <video mat-card-image controls poster="../../assets/images/kotiz_intro.jpg">
      <source src="../../assets/videos/kotiz.mp4" type="video/mp4">
    </video>
    <mat-card-content>
      <div align="justify">
        Kotiz personal financial assistant helps individuals and small business owners manage their financial lives. We allow them to instantly and securely connect with qualified financial advisors using an Artificial Intelligence (AI) conversational chatbot. Kotiz is a  collaborative personal finance assistant for connecting financial advisors and those seeking financial advice. Kotiz offers useful and simple digital tools to monitor and pay your bills, track your spending, and get the right direction when making a financial decision like buying a house, refinancing, saving money, or investing responsibly. Our platform also helps users learn from Kotiz's community experience and explore affordable products and services. Kotiz is an advanced financial management platform that allows you to manage your entire financial life across a single integrated platform.
        <br/>
        Because our solution leverages blockchain and cryptography, it allows the exchange and storage of sensitive information, documents, and contracts and enables fast, secure payments. Big data and AI can analyze patterns in users' metadata, so the more the community grows, the more Kotiz can learn and give back to its community. Fast payments and smart chatbots put the tools you need at your fingertips to stay on track and get the best financial advice when you need it.
        <br/>
        The Kotiz team believes that possibilities for personal savings and financial growth are endless if individuals have fair access to the best technologies, just as companies and wealthy individuals do. We help individuals save money and grow their wealth by empowering their financial management skills. Individuals can significantly improve their financial lives, enabling them to provide prosperity for their loved ones; if they own appropriate technology and have fair access to advisory resources. While many financial solution companies fail to grow their customers' wealth and promote their financial stability, Kotiz is here to do just that!
        <div class="vertical-spacer-20 flex horizontal layout"></div>

        <strong><h3>Solutions:</h3></strong>

        <ul class="first-list">
            <li>Connect with financial advisors</li>
            <li>Monitor and pay bills</li>
            <li>Find affordable products and services</li>
            <li>Discover competitive financing solutions</li>
            <li>Manage your entire financial life</li>
        </ul>
    </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" onclick="onTidioChatApiReady()">Get help you need!</button>
    </mat-card-actions>
  </mat-card>

  <div class="vertical-spacer-20 flex horizontal layout"></div>

  <mat-card class="footer">
    <img src="../../assets/images/chicago.png" alt="Chicago" class="chicago">
  </mat-card>
</div>