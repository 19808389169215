import { ContactUsComponent } from './contact-us/contact-us.component';
import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenavContent, MatSidenav } from '@angular/material/sidenav';
import { MAT_DRAWER_CONTAINER, MatDrawer } from '@angular/material/sidenav/drawer';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'kotiz-io';

  @ViewChild('drawer')
  drawerContent: MatSidenav;

  @ViewChild('content')
  contentEl: MatSidenavContent;

  isHandset = false;

  constructor(private breakpointObserver: BreakpointObserver, public dialog: MatDialog, private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      "telegram",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/telegram.svg")
    );
  }

  ngOnInit() {
    this.breakpointObserver
      .observe(Breakpoints.Handset)
      .subscribe((state: BreakpointState) => {
        this.isHandset = state.matches;
      });
  }

  goToBookmark(id: string) {
    const element = document.querySelector(id);
    if (element != null) {
      element.scrollIntoView(true);
    }
  }

  toggleMenu() {
    if (this.isHandset) {
      this.drawerContent.toggle();
    }
  }

  openContactUs() {
    const dialogConfig = new MatDialogConfig();
    const contentNativeElement = this.contentEl.getElementRef().nativeElement;
    const contentRect = contentNativeElement.getBoundingClientRect();
    dialogConfig.autoFocus = true;

    if (this.isHandset) {
      dialogConfig.maxWidth = '100vw';
      dialogConfig.width = contentNativeElement.offsetWidth + 'px';
      dialogConfig.height = (contentNativeElement.offsetHeight - 56) + 'px';
      dialogConfig.position = {
        top: (contentRect.top + 56) + 'px',
        left: contentRect.left + 'px',
        bottom: contentRect.bottom + 'px',
        right: contentRect.right + 'px'
      };
    } else {
      dialogConfig.width = '500px';
      dialogConfig.height = '520px';
    }

    const dialogRef = this.dialog.open(ContactUsComponent, dialogConfig);
  }
}
