<div class="flex vertical layout" *ngIf="isCurrentStep(loginInfoStep)">
    <form [formGroup]="loginInfoStepForm" novalidate (ngSubmit)="next()" class="flex vertical layout">
        <mat-card class="flex vertical layout">
            <mat-card-content class="flex vertical layout">
                <h2>Login or Register</h2>
                <div class="flex vertical layout">
                    <div class="row">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Email" formControlName="email" type="email">
                            <mat-error *ngIf="loginInfoStepForm.controls['email'].hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="loginInfoStepForm.controls['email'].hasError('pattern')">
                                Email is <strong>invalid</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="full-width">
                            <textarea matInput #message minlength="42" maxlength="42" placeholder="Ethereum Address"
                                formControlName="userEthAddress" [rows]="2"></textarea>
                            <mat-hint align="end">{{userEthAddress.length}} / 42</mat-hint>
                            <mat-error *ngIf="loginInfoStepForm.controls['userEthAddress'].hasError('required')">
                                Ethereum Address is <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="loginInfoStepForm.controls['userEthAddress'].hasError('minlength')">
                                Ethereum Address is <strong>too short</strong>
                            </mat-error>
                            <mat-error *ngIf="loginInfoStepForm.controls['userEthAddress'].hasError('maxlength')">
                                Ethereum Address is <strong>too long</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions class="actions">
                <button mat-raised-button color="secondary" [disabled]="isFirstStep" (click)="back()">Back</button>
                <button mat-raised-button color="primary" type="submit" [disabled]="isLastStep">Login or Register</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>

<div class="flex vertical layout" *ngIf="isCurrentStep(userInfoStep)">
    <form [formGroup]="userInfoStepForm" novalidate (ngSubmit)="next()" class="flex vertical layout">
        <mat-card class="flex vertical layout">
            <mat-card-content class="flex vertical layout">
                <h2>User Information</h2>
                <div class="flex vertical layout">
                    <div class="row">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="First name" formControlName="firstName">
                            <mat-error *ngIf="userInfoStepForm.controls['firstName'].hasError('required')">
                                First name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Last name" formControlName="lastName">
                            <mat-error *ngIf="userInfoStepForm.controls['lastName'].hasError('required')">
                                Last name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Country" formControlName="country">
                            <mat-error *ngIf="userInfoStepForm.controls['country'].hasError('required')">
                                Country is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="City" formControlName="city">
                            <mat-error *ngIf="userInfoStepForm.controls['city'].hasError('required')">
                                City <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="full-width">
                            <textarea matInput #addresse minlength="16" maxlength="256" placeholder="Home Address"
                                formControlName="addresse" [rows]="2"></textarea>
                            <mat-hint align="end">{{addresse.length}} / </mat-hint>
                            <mat-error *ngIf="userInfoStepForm.controls['addresse'].hasError('required')">
                                Home Address is <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="userInfoStepForm.controls['addresse'].hasError('minlength')">
                                Home Address is <strong>too short</strong>
                            </mat-error>
                            <mat-error *ngIf="userInfoStepForm.controls['addresse'].hasError('maxlength')">
                                Home Address is <strong>too long</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions class="actions">
                <button mat-raised-button color="secondary" [disabled]="isFirstStep" (click)="back()">Back</button>
                <button mat-raised-button color="primary" type="submit" [disabled]="isLastStep">Next</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>

<div class="flex vertical layout" *ngIf="isCurrentStep(saleStep)">
    <form [formGroup]="saleStepForm" novalidate (ngSubmit)="next()" class="flex vertical layout">
        <mat-card class="flex vertical layout">
            <mat-card-content class="flex vertical layout">
                <h2>Token Sale</h2>
                <div class="flex vertical layout">
                    <div class="row">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="You Pay (ETH)" formControlName="ethQuantity">
                            <mat-error *ngIf="saleStepForm.controls['ethQuantity'].hasError('required')">
                                You Pay is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="You Get (KOFI)" formControlName="tokenQuantity">
                            <mat-error *ngIf="saleStepForm.controls['tokenQuantity'].hasError('required')">
                                You Get is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions class="actions">
                <button mat-raised-button color="secondary" [disabled]="isFirstStep" (click)="back()">Back</button>
                <button mat-raised-button color="primary" type="submit" [disabled]="isLastStep">Next</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>

<div class="flex vertical layout" *ngIf="isCurrentStep(confirmationStep)">
    <form [formGroup]="confirmationStepForm" novalidate (ngSubmit)="next()" class="flex vertical layout">
        <mat-card class="flex vertical layout">
            <mat-card-content class="flex vertical layout">
                <h2>Confirmation</h2>
            </mat-card-content>
            <mat-card-actions class="actions">
                <button mat-raised-button color="secondary" [disabled]="isFirstStep" (click)="back()">Back</button>
                <button mat-raised-button color="primary" type="submit" [disabled]="isLastStep">Next</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>