import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {

  submited = false;
  loading = false;

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient) { }

  contactUsForm = this.fb.group({
    company: null,
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    email: [null, Validators.compose([Validators.required,
      Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
    message: [null, Validators.compose([
      Validators.required, Validators.minLength(3), Validators.maxLength(800)])
    ]
  });

  onSubmit() {
    this.sendMessage();
  }

  sendMessage() {
    if (!this.contactUsForm.valid) {
      return;
    }

    const url = 'https://kotiz.com/kotiz-one/us-central1/sendMail';

    const httpOptions = {
      headers: {
        'Content-Type' : 'application/json',
        'Accept': 'text/html'
      },
      withCredentials: true
    };

    this.loading = true;
    this.http.post(url, this.contactUsForm.value, httpOptions)
      .pipe().subscribe().add(() => {
        this.loading = false;
        this.submited = true;
      });
  }
}
